const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 接口文档地址：http://192.168.0.31:9900/doc.html#/trains/order-controller/checkRefChaTimeUsingPOST
const trains_order_checkRefChaTime = (pParameter) => {
    if (!pParameter) pParameter = {};
    const type = pParameter.type || 1;//1-改签 2-退票

    const p = {
        method: 'post',
        urlSuffix: '/trains/order/checkRefChaTime',
        data: {
            type,
        },
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = trains_order_checkRefChaTime;
