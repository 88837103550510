import Q from "q";
import moment from "moment";
// 详情
import trains_buyerOrder_getBuyerOrderDetail from "@/lib/data-service/trains/trains_buyerOrder_getBuyerOrderDetail.js";
// // 获取订单支付状态
import trains_order_orderPaymentStatus from "@/lib/data-service/trains/trains_order_orderPaymentStatus.js";
// 取消订单
import trains_order_applyForCancel from "@/lib/data-service/trains/trains_order_applyForCancel.js";

// 退票
import trains_order_applyForRefund from "@/lib/data-service/trains/trains_order_applyForRefund.js";
// 保险更新
import trains_order_updateInsuranceStatus from "@/lib/data-service/trains/trains_order_updateInsuranceStatus.js";
// 验证是否可以改签
import trains_order_checkEndorsed from "@/lib/data-service/trains/trains_order_checkEndorsed.js";

// 日志//
import trains_OrderLog_getBuyerOrderLogList from "@/lib/data-service/trains/trains_OrderLog_getBuyerOrderLogList.js";

import trains_order_checkRefChaTime from "@/lib/data-service/trains/trains_order_checkRefChaTime";

export default {
    components: {},
    data() {
        return {
            loading: false,
            detail: {}, //orderStatus 1待确定，2待支付，3待出票，4出票成功，5出票失败，6取消
            insurance: {
                insuranceName: "",
                insuranceNo: "",
                insuranceStatus: "",
                insurancePrice: "",
                insuranceStatusText: "",
            },
            batchVisible: false,
            refundVisible: false,
            changeVisible: false,

            refundOperationVisible: false,
            refundSuccess: false,
            passengerInfo: {ticketPrice: ""},
            sn: [],
            fightVisble: false,
            insuranceVisble: false,
            status: 6, //1未支付，2已支付，3已改签，4抢票中，5改签中，6已完成，7已取消
            showType: 1,
            showTypeOptions: [
                {label: 1, value: "订单详情"},
                {label: 2, value: "订单日志"},
            ],
            orderLog: [],
            timer: null,
            logList: [],
            logForm: {
                orderNo: "",
                currentPage: 1,
                pageSize: 10,
            },
            logPager: {
                total: 0,
                pageSizes: [10, 20, 30, 40, 50],
            },

            dialog_refund_or_change_msg: {
                show: false,
                title: `温馨提示`,
                top: `40vh`,
                width: `600px`,
                msg_row_list: [],
            }
        };
    },
    computed: {
        span: function () {
            const status = this.status;
            if (status === 1 || status === 4) {
                return 5;
            } else {
                return 7;
            }
        },
    },
    methods: {
        // 详情
        req_detail(params) {
            clearInterval(this.timer);
            this.loading = true;
            const _this = this;
            Q.when()
                .then(function () {
                    return trains_buyerOrder_getBuyerOrderDetail(params);
                })
                .then(function (res) {
                    const detail = res;
                    detail.detailResults.forEach(function (item) {
                        item.forEach(function (params, index) {
                            params.key = index;
                            params.gmtStart = moment(params.gmtStart).format(
                                "YYYY-MM-DD HH:mm"
                            );
                        });

                        if (item[0].passengerType) {
                            switch (item[0].passengerType) {
                                case 1:
                                    item[0].passengerTypeText = "成人";
                                    break;
                                case 2:
                                    item[0].passengerTypeText = "儿童";
                                    break;
                                case 3:
                                    item[0].passengerTypeText = "学生";
                                    break;
                                case 4:
                                    item[0].passengerTypeText = "军残";
                                    break;
                            }
                        }

                        if (item[0].passengerCardType) {
                            switch (parseInt(item[0].passengerCardType)) {
                                case 1:
                                    item[0].passengerCardTypeText = "身份证";
                                    break;
                                case 2:
                                    item[0].passengerCardTypeText = "儿童无证件";
                                    break;
                                case 3:
                                    item[0].passengerCardTypeText = "港澳通行证";
                                    break;
                                case 4:
                                    item[0].passengerCardTypeText = "台湾通行证";
                                    break;
                                case 5:
                                    item[0].passengerCardTypeText = "护照护照";
                                    break;
                            }
                        }

                        if (item[0].passengerCardNumber) {
                            item[0].passengerCardNumberFront = item[0].passengerCardNumber.substr(
                                0,
                                4
                            );
                            item[0].passengerCardNumberEnding = item[0].passengerCardNumber.substr(
                                -3,
                                3
                            );
                        }
                    });

                    _this.detail = detail;
                    _this.loading = false;
                    let first = false;

                    _this.detail.detailResults.forEach(function (item) {
                        if (item[0].insuranceStatus !== 2 && item[0].ticketStatus === 3) {
                            first = true;
                        }
                    });
                    if (first)
                        _this.req_update_insurance({
                            orderNo: detail.orderNo,
                            thirdOrderNo: detail.thirdOrderNo,
                        });
                    if (_this.detail.orderStatus !== 2) return;
                    _this.timer = setInterval(_this.count_down, 1000);
                });
        },
        // 支付状态
        req_order_status(params) {
            return Q.when().then(function () {
                return trains_order_orderPaymentStatus(params);
            });
        },
        // 取消
        req_cancel_order(params) {
            const _this = this;
            Q.when()
                .then(function () {
                    return trains_order_applyForCancel(params);
                })
                .then(function (res) {
                    _this.$message.success("已申请取消订单，请稍候刷新查看！");
                    _this.$router.back(-1);
                });
        },
        // 保险
        req_insurance(row) {
            const insurance = {};
            insurance.insuranceName = row.insuranceName;
            insurance.insuranceNo = row.insuranceNo;
            insurance.insuranceStatus = row.insuranceStatus;
            insurance.insurancePrice = row.insurancePrice;
            insurance.insuranceStatusText = row.insuranceStatusText;
            this.insurance = [insurance];
            this.insuranceVisble = true;
        },
        // 退票
        req_refund_ticket(params) {
            return Q.when().then(function () {
                return trains_order_applyForRefund(params);
            });
        },
        // 验证能否改签
        req_verify_change(params) {
            return Q.when().then(function () {
                return trains_order_checkEndorsed(params);
            });
        },
        req_log_list(params) {
            const _this = this;
            Q.when()
                .then(function () {
                    return trains_OrderLog_getBuyerOrderLogList(params);
                })
                .then(function (res) {
                    _this.logList = res.pageResult.pageData;
                    _this.logPager.total = res.pageResult.totalCount;
                    document.documentElement.scrollTop = 0;
                });
        },
        req_update_insurance(params) {
            const _this = this;
            Q.when().then(function () {
                return trains_order_updateInsuranceStatus(params);
            });
        },
        change_show(val) {
            if (val === 2) {
                this.req_log_list(this.logForm);
            }
        },
        change_log_size(pageSize) {
            this.logForm.pageSize = pageSize;
            this.logForm.currentPage = 1;
            this.req_log_list(this.logForm);
        },
        change_log_current(currentPage) {
            this.logForm.currentPage = currentPage;
            this.req_log_list(this.logForm);
        },
        count_down() {
            const detail = this.detail;
            const gmtLastPayment = detail.gmtLastPayment;
            const now = moment();
            const diff = moment(gmtLastPayment).diff(now, "seconds");

            if (diff > 0) {
                detail.countdownToPayment =
                    Math.floor(diff / 60) + "分" + (diff % 60) + "秒";
            } else if (diff <= 0) {
                detail.countdownToPayment = "00分00秒";
                if (diff === 0) this.req_detail({orderNo: this.$route.query.orderNo});
            }
        },
        // 去支付
        to_payment(detail) {
            const _this = this;
            Q.when()
                .then(function () {
                    return _this.req_order_status({orderNo: detail.orderNo});
                })
                .then(function (res) {
                    if (res.ifTimeout === 1) {
                        _this.$router.push({
                            name: "distributor-train-front-pay",
                            query: {
                                orderNo: detail.orderNo,
                                type: detail.orderType === 1 ? "reserve" : "booking",
                            },
                        });
                    } else if (res.ifTimeout === 2) {
                        _this.$message.warning("已超过支付时间，请重新下单！");
                        _this.req_detail({orderNo: detail.orderNo});
                    }
                });
        },
        // 去搜索页预订返程
        to_search(type) {
            const now = moment()
                .utcOffset(480)
                .format("YYYY-MM-DD HH:mm:ss");
            const gmtStart = moment(this.detail.gmtStart).format("YYYY-MM-DD");
            let fromStationCode;
            let toStationCode;
            let time;
            if (type === "again") {
                fromStationCode = this.detail.fromStationCode;
                toStationCode = this.detail.toStationCode;
                time =
                    moment(gmtStart) <= moment(now)
                        ? moment(now)
                            .add(1, "d")
                            .format("YYYY-MM-DD")
                        : gmtStart;
            } else if (type === "back") {
                fromStationCode = this.detail.toStationCode;
                toStationCode = this.detail.fromStationCode;
                time =
                    moment(gmtStart) <= moment(now)
                        ? moment(now)
                            .add(1, "d")
                            .format("YYYY-MM-DD")
                        : moment(gmtStart)
                            .add(1, "d")
                            .format("YYYY-MM-DD");
            }
            this.$router.push({
                name: "distributor-train-front-search",
                query: {
                    fromStationCode,
                    toStationCode,
                    time,
                    onlySearch: 0,
                },
            });
        },
        cancel_order(orderNo) {
            const _this = this;
            this.$confirm("确定取消订单？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                Q.when()
                    .then(function () {
                        return _this.req_order_status({orderNo});
                    })
                    .then(function (res) {
                        if (res.ifTimeout === 1) {
                            _this.req_cancel_order({orderNo});
                        } else if (res.ifTimeout === 2) {
                            _this.req_detail({orderNo: _this.detail.orderNo});
                        }
                    });
            });
        },
        // 取消改签
        cancel_change() {
            this.$confirm("确定取消改签?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "取消成功!",
                    });
                })
                .catch(() => {
                });
        },
        select_all(val, index) {
            const sn = this.detail.detailResults[index][0].sn;
            const snArr = this.sn;
            if (val.length) {
                if (snArr.length) {
                    const find = snArr.indexOf(sn);
                    if (find !== -1) return;
                }
                snArr.push(sn);
            } else {
                const find = snArr.indexOf(sn);
                if (find === -1) return;
                snArr.splice(find, 1);
            }
        },
        select_item(val, index) {
            const sn = this.detail.detailResults[index][0].sn;
            const snArr = this.sn;
            if (val.length) {
                snArr.push(sn);
            } else {
                const find = snArr.indexOf(sn);
                snArr.splice(find, 1);
            }
        },
        refund_operation() {
        },
        refund_ticket() {
            const _this = this;
            const passengerInfo = _this.passengerInfo;
            const detail = _this.detail;
            const params = {};
            params.buyTicketsType = detail.buyTicketsType;
            params.electronicTicketNumber = detail.electronicTicketNumber;
            params.gmtStart = passengerInfo.gmtStart;
            params.orderNo = _this.detail.orderNo;
            params.passengerType = passengerInfo.passengerType;
            params.thirdOrderNo = detail.thirdOrderNo;
            params.ticketSerialNo = passengerInfo.ticketSerialNo;
            params.passengerSn = passengerInfo.sn;
            Q.when()
                .then(function () {
                    return _this.req_refund_ticket(params);
                })
                .then(function (res) {
                    _this.refundOperationVisible = false;
                    setTimeout(() => {
                        _this.refundSuccess = true;
                        _this.req_detail({orderNo: _this.$route.query.orderNo});
                    }, 100);
                });
        },

        handleClose() {
            this.insuranceVisble = false;
            this.insurance = null;
        },
        cancel_refund_ticket() {
            this.refundOperationVisible = false;
            setTimeout(function () {
                this.passengerInfo = {ticketPrice: ""};
            }, 100);
        },

        __show_dialog_refund_or_change_msg(pParameter) {
            if (!pParameter) pParameter = {};
            const msg = pParameter.msg || ``;

            const __this = this;

            const msg_row_list = [];
            msg_row_list.push(`当前时间不提供服务。`);
            msg_row_list.push(msg);
            msg_row_list.push(`其他时间须去火车站窗口办理。`);
            __this.dialog_refund_or_change_msg.show = true;
            __this.dialog_refund_or_change_msg.msg_row_list = msg_row_list;
        },

        __btn_refund_click(pRow) {
            const __this = this;
            Q.when()
                .then(function () {
                    return trains_order_checkRefChaTime({
                        type: 2,
                    });
                })
                .then(function (data) {
                    let result = data.result;
                    let checkText = data.checkText;

                    // result = false;
                    // checkText = `在线退票时间为：周一至周日05:00-01:00`;

                    if (result) {
                        //检查结果 true-可退改 false-不可退改
                        __this.passengerInfo = pRow;
                        __this.refundOperationVisible = true;
                        return;
                    }

                    __this.__show_dialog_refund_or_change_msg({msg: checkText});
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
            ;
        },

        __btn_change_click(sn, row) {
            const __this = this;

            if (__this.sn.length > 1) {
                const isHaveSleeper = false;
                const detailResults = __this.detail.detailResults;
                for (let i = 0; i < detailResults.length; i++) {
                    let item = detailResults[i][0];
                    if (item.seatName === "软卧" || item.seatName === "硬卧")
                        return __this.$message.warning(
                            "卧铺不能批量改签只能提交单个乘客申请改签"
                        );
                }
            }

            Q.when()
                .then(function () {
                    return trains_order_checkRefChaTime({
                        type: 1,
                    });
                })
                .then(function (data) {
                    let result = data.result;
                    let checkText = data.checkText;

                    // result = false;
                    // checkText = `在线退票时间为：周一至周日05:00-01:00`;

                    if (!result) {
                        //检查结果 true-可退改 false-不可退改
                        __this.__show_dialog_refund_or_change_msg({msg: checkText});
                        return Q.reject({
                            disable_log_error: true,
                        });
                    }

                    return __this.req_verify_change({sn});
                })
                .then(function (res) {
                    if (!res.canChange) {
                        __this.$message.info("已超过改签时间");
                        return;
                    }

                    const now = moment()
                        .utcOffset(480)
                        .format("YYYY-MM-DD HH:mm:ss");
                    const gmtStart = row.gmtStart;
                    const trainDate =
                        moment(now) >= moment(gmtStart)
                            ? moment(now)
                                .add(1, "days")
                                .format("YYYY-MM-DD")
                            : moment(gmtStart).format("YYYY-MM-DD");
                    const query = {
                        orderNo: __this.detail.orderNo,
                        fromStationCode: row.fromStationCode,
                        toStationCode: row.toStationCode,
                        trainCode: row.trainCode,
                        trainDate,
                    };
                    query.sn = sn.join();
                    __this.$router.push({
                        name: "distributor-train-front-ticket-change-search",
                        query,
                    });
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
            ;
        },

        __btn_batch_change_click() {
            const __this = this;

            if (__this.sn.length <= 0) {
                __this.$confirm("请选择要改签的订单", "提示", {
                    cancelButtonText: "关闭",
                    showConfirmButton: false,
                    type: "warning",
                });
                return;
            }

            Q.when()
                .then(function () {
                    return trains_order_checkRefChaTime({
                        type: 1,
                    });
                })
                .then(function (data) {
                    let result = data.result;
                    let checkText = data.checkText;

                    // result = false;
                    // checkText = `在线退票时间为：周一至周日05:00-01:00`;

                    if (!result) {
                        //检查结果 true-可退改 false-不可退改
                        __this.__show_dialog_refund_or_change_msg({msg: checkText});
                        return Q.reject({
                            disable_log_error: true,
                        });
                    }

                    const detailResults = __this.detail.detailResults;
                    const snArr = __this.sn;
                    const passenger = detailResults.filter(function (item) {
                        for (let i = 0; i < snArr.length; i++) {
                            const sn = snArr[i];
                            if (item[0].sn === sn) return item;
                        }
                    });
                    __this.__btn_change_click(__this.sn, passenger[0][0]);
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
            ;
        },
    },
    watch: {},
    mounted() {
    },
    activated() {
        document.documentElement.scrollTop = 0;
        this.logForm.orderNo = this.$route.query.orderNo;
        this.req_detail({orderNo: this.$route.query.orderNo});
    },
    deactivated() {
        clearInterval(this.timer);
    },
};
